import React from 'react'
import TrustedBySection from '../views/landing/TrustedBySection'
import OurExpertise from '../views/landing/OurExpertise'
import ServicesSection from '../views/landing/ServicesSection'
import FaqSection from '../views/landing/FaqSection'
import { expertiseArray } from '../constants/Expertise'
import MeetUs from '../views/team/MeetUs'
import OurTeam from '../views/team/OurTeam'
import { useMediaQuery } from '@mui/material'
import classNames from 'classnames'
import { Helmet } from 'react-helmet-async'
import {aboutFaqs } from '../constants/aboutFaqs'

interface Props { }

const About = () => {
    const isLessThan1331 = useMediaQuery('(max-width: 1331px)');

    return (
      <div className="mt-48 mdmax:mt-36">
        <Helmet>
          <title>Web Design & Development Agency in USA | Custom App Solutions</title>
          <meta
            name="description"
            content="Partner with Atini Studio for top mobile app development, custom web application development, and expert website design in LA."
          />
          <link rel="alternate" hrefLang="en-US" href="https://atinistudio.com/about" />
          <link rel="canonical" href="https://atinistudio.com/about" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://atinistudio.com/about" />
          <meta property="og:title" content="Web Design & Development Agency in USA | Custom App Solutions" />
          <meta
            property="og:description"
            content="Partner with Atini Studio for top mobile app development, custom web application development, and expert website design in LA."
          />
          <meta property="og:image" content="https://atinistudio.com/assets/atini-logo-colored.svg" />
          <meta property="og:site_name" content="Atini Studio Inc." />
          <meta property="og:locale" content="en_US" />
          <meta property="og:phone_number" content="+1 (909) 503-2187" />
          <meta property="og:email" content="general@atinistudio.com" />
        </Helmet>
        <MeetUs />
        <div className={classNames('breaktwo:mb-12 mb-20 mdmax:mt-16', isLessThan1331 ? 'mt-24' : 'mt-44')}>
          <OurExpertise title="our_expertise" description="most_up_to_date_tech" displayItems={expertiseArray} />
        </div>
        <ServicesSection />
        <FaqSection questionAnswerArray={aboutFaqs} />
      </div>
    );
}

export default About