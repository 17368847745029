import React from 'react'
import Header from '../components/common/Header'
import Banner from '../components/common/Banner'
import DevServices from '../views/services/mobileAppDev/DevServices'
import OurExpertise from '../views/landing/OurExpertise'
import ProjectsSection from '../views/landing/ProjectsSection'
import { mobileExpertiseArray } from '../constants/Expertise'
import { useMediaQueries } from '../contexts/media-query'
import classNames from 'classnames'
import FaqSection from '../views/landing/FaqSection'
import { mobileAppDevFaqs } from '../constants/mobileAppDevFaqs'
import { Helmet } from 'react-helmet-async'

interface Props { }

const MobileAppDev = (props: Props) => {
    const { } = props
    return (
      <>
        <Helmet>
          <title>Mobile App Development Company in LA | Top Apps Developers</title>
          <meta
            name="description"
            content="Trust Atini Studio, a renowned app development company in LA, for innovative mobile app development and custom app designing & development services."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://atinistudio.com/mobile-app-development" />
          <meta property="og:title" content="Mobile App Development Company in LA | Top Apps Developers" />
          <meta
            property="og:description"
            content="Trust Atini Studio, a renowned app development company in LA, for innovative mobile app development, custom app designing, and development services that transform your ideas into reality."
          />
          <meta property="og:image" content="https://atinistudio.com/assets/atini-logo-colored.svg" />
          <meta property="og:site_name" content="Atini Studio Inc." />
          <meta property="og:locale" content="en_US" />
          <meta property="og:phone_number" content="+1 (909) 503-2187" />
          <meta property="og:email" content="general@atinistudio.com" />
          <link rel="alternate" hrefLang="en-US" href="https://atinistudio.com/mobile-app-development" />
          <link rel="canonical" href="https://atinistudio.com/mobile-app-development" />
        </Helmet>
        <Header
          pillLabel="mobile_app_dev"
          title="mobile_app_dev_motto"
          description="mobile_app_dev_description"
          renderButton
          buttonText="get_instant_quote"
          image="mobile_app_dev.svg"
          altTag="Mobile app development company LA"
        />
        <Banner pillLabel={'our_expertise'} title={'expertise_motto'} description={'expertise_description'} />
        <DevServices />
        <div className={classNames('breaktwo:mb-12 mb-20')}>
          <OurExpertise title="tech_we_use" description="tech_we_use_description" displayItems={mobileExpertiseArray} />
        </div>
        <ProjectsSection />
        <FaqSection questionAnswerArray={mobileAppDevFaqs} />
      </>
    );
}

export default MobileAppDev