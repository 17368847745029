import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RoundedArrowButton } from '../../components/buttons';
import Info from '../../components/common/Info';
import RoundInfo from '../../components/common/RoundInfo';
import SubInfo from '../../components/common/SubInfo';
import GapAround from '../../components/hoc/GapAround';
import { imageUrl } from '../../utils';
import { useMediaQuery } from '@mui/material';
const tiltedFlower = imageUrl('tilt-flower.svg');
const pumpedBulb = imageUrl('pumpbulb.svg');
const pumpedBulbSm = imageUrl('pumpbulb-sm.svg');

const MeetUs = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isLessThan899 = useMediaQuery('(max-width: 899px)');
  const isLessThan1280 = useMediaQuery('(max-width: 1280px)');

  return (
    <GapAround spaceTaken={1} childrenClasses="">
      <div className="absolute left-0 top-[30vh] z-[0.9] breaktwo:hidden">
        <img src={tiltedFlower} alt="tilt flower" />
      </div>
      <div className="absolute flex justify-center items-center left-14 top-1/6 right-14 mdmax:left-4 mdmax:right-4 mdmax:top-14 -z-[1] breaktwo:opacity-30">
        <img src={isLessThan899 ? pumpedBulbSm : pumpedBulb} alt={isLessThan899 ? "pumpbulb sm" : "pumpbulb"} />
      </div>
      <div className="flex flex-col items-center">
        <RoundInfo containerClasses="mb-8 breaktwo:mb-6">{t('top_talented_team')}</RoundInfo>
        <Info
          containerClasses="text-center w-[65%] breaktwo:w-full breaktwo:px-5 mb-6 breaktwo:mb-4"
          typographyStyles={{
            fontFamily: `var(--fontSecondary) !important`,
            fontSize: isLessThan1280 ? (isLessThan899 ? 32 : 36) : 42,
          }}>
          {t('meat_our_team')}
        </Info>
        <SubInfo containerClasses="md:w-[50%] text-center">{t('not_typical_agency')}</SubInfo>
        <RoundedArrowButton
          text={t('get_instant_quote')}
          containerClasses="bg-yellow w-72 mt-12 breaktwo:mt-8"
          onClick={() => navigate('/contact-us')}
        />
      </div>
    </GapAround>
  );
};

export default MeetUs;
