import React from 'react'
import Header from '../components/common/Header'
import Banner from '../components/common/Banner'
import OurExpertise from '../views/landing/OurExpertise'
import ProjectsSection from '../views/landing/ProjectsSection'
import ServiceList from '../views/dev/ServiceList'
import { web_dev_service_items } from '../constants/servicesItems'
import { expertiseArray } from '../constants/Expertise'
import classNames from 'classnames'
import { useMediaQuery } from '@mui/material'
import FaqSection from '../views/landing/FaqSection'
import {webAppDevFaqs} from '../constants/webAppDevFaqs'
import { Helmet } from 'react-helmet-async'
const WebDevServices = () => {
    const isLessThan1331 = useMediaQuery('(max-width: 1331px)');

    return (
      <>
        <Helmet>
          <title>Top Web Developers in LA | Leading Web Development Company</title>
          <meta
            name="description"
            content="Connect with the top LA web development agency for custom website solutions and front-end web development services that deliver results and enhance your brand."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://atinistudio.com/web-app-development" />
          <meta property="og:title" content="Top Web Developers in LA | Leading Web Development Company" />
          <meta
            property="og:description"
            content="Partner with Atini Studio, a top LA web development agency, for custom website solutions and front-end development services that elevate your brand and deliver measurable results."
          />
          <meta property="og:image" content="https://atinistudio.com/assets/atini-logo-colored.svg" />
          <meta property="og:site_name" content="Atini Studio Inc." />
          <meta property="og:locale" content="en_US" />
          <meta property="og:phone_number" content="+1 (909) 503-2187" />
          <meta property="og:email" content="general@atinistudio.com" />
          <link rel="alternate" hrefLang="en-US" href="https://atinistudio.com/web-app-development" />
          <link rel="canonical" href="https://atinistudio.com/web-app-development" />
        </Helmet>
        <Header
          pillLabel="web_dev_pill"
          title="web_dev_motto"
          description="web_app_dev_description"
          renderButton
          buttonText="get_instant_quote"
          image="web_dev_header.svg"
          altTag="Web development company in LA"
        />
        <Banner pillLabel="custom_web_app_pill" title="web_dev_title" description="web_app_description" />
        <ServiceList items={web_dev_service_items} />
        <div className={classNames('breaktwo:mb-12 mb-20')}>
          <OurExpertise title="our_expertise" description="most_up_to_date_tech" displayItems={expertiseArray} />
        </div>
        <ProjectsSection />
        <FaqSection questionAnswerArray={webAppDevFaqs} />
      </>
    );
}
export default WebDevServices