import { Typography } from '@mui/material';
import classNames from 'classnames';
import { TwContainer } from '../../typings/tw.types';
interface InfoProps extends TwContainer {}
const Info = ({ children, className, containerClasses, typographyStyles, id, variant='h1' }: InfoProps) => {
  return (
    <div className={classNames(containerClasses, 'whitespace-pre-line')} id={id}>
      <Typography variant={variant} className={`${className}`} style={{ fontFamily: 'var(--fontBase)', ...typographyStyles }}>
        {children}
      </Typography>
    </div>
  );
};

export default Info;
