import React from 'react'
import Header from '../components/common/Header'
import Banner from '../components/common/Banner'
import ServiceList from '../views/dev/ServiceList'
import { software_serviceItems } from '../constants/servicesItems'
import SoftwareServiceBanner from '../views/services/softwareConsult/SoftwareServiceBanner'
import FaqSection from '../views/landing/FaqSection'
import {softwareConsultationFaqs} from '../constants/softwareConsultationFaqs'
import { Helmet } from 'react-helmet-async'
  
interface Props { }

const SoftwareConsulting = (props: Props) => {
    const { } = props

    return (
      <>
        <Helmet>
          <title>Software Consulting Services LA | Digital Transformation Experts</title>
          <meta
            name="description"
            content="Transform your business with digital transformation consulting in LA, expert IT strategy consulting, and tailored software consulting services at Atini Studio."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://atinistudio.com/software-consultation" />
          <meta property="og:title" content="Software Consulting Services LA | Digital Transformation Experts" />
          <meta
            property="og:description"
            content="Transform your business with digital transformation consulting, expert IT strategy consulting, and tailored software consulting services at Atini Studio in LA."
          />
          <meta property="og:image" content="https://atinistudio.com/assets/atini-logo-colored.svg" />
          <meta property="og:site_name" content="Atini Studio Inc." />
          <meta property="og:locale" content="en_US" />
          <meta property="og:phone_number" content="+1 (909) 503-2187" />
          <meta property="og:email" content="general@atinistudio.com" />
          <link rel="alternate" hrefLang="en-US" href="https://atinistudio.com/software-consultation" />
          <link rel="canonical" href="https://atinistudio.com/software-consultation" />
        </Helmet>
        <Header
          pillLabel="software_consulting"
          title="software_consulting_motto"
          description="software_consulting_description"
          renderButton
          buttonText="get_instant_quote"
          image="software.svg"
          altTag="Tech consulting services Los Angeles"
        />
        <Banner
          pillLabel="software_consulting_banner_pill"
          title="software_consulting_banner_title"
          description="software_consulting_banner_description"
        />
        <ServiceList items={software_serviceItems} />
        <SoftwareServiceBanner pillLabel={'software_consulting'} />
        <FaqSection questionAnswerArray={softwareConsultationFaqs} />
      </>
    );
}

export default SoftwareConsulting