import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomLi from '../../components/common/CustomLi';
import Info from '../../components/common/Info';
import RoundInfo from '../../components/common/RoundInfo';
import SubInfo from '../../components/common/SubInfo';
import GapAround from '../../components/hoc/GapAround';
import { imageUrl } from '../../utils';
import { RoundedArrowButton } from '../../components/buttons';
import TextArea from '../../components/common/TextArea';
import { CSSProperties, useEffect, useState } from 'react';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import Checkbox from '../../components/common/Checkbox';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackwardIcon from '@mui/icons-material/ArrowBack';
import { ComponentDivProps } from '../../typings/html.types';
import { postApi } from '../../utils/apiClient';
import { useTranslation } from 'react-i18next';
import { Alert, AlertColor, Snackbar, useMediaQuery } from '@mui/material';
import ErrorText from '../../components/common/ErrorText';
import { useSSR } from 'use-ssr';

/*
Mobile / Web / Both
From scratch / add new feature
*/
const projectList = [
  'Fintech Application',
  'Data Mining Tool',
  'Open Banking App',
  'Marketplace',
  'API',
  'Analytics / BI Dashboard',
  'Community/Social Network',
  'Artificial Intelligence Application',
  'Al on the edge',
  'Fintech Application',
  'Data Mining Tool',
  'Open Banking App',
  'Marketplace',
  'API',
  'Analytics / BI Dashboard',
  'Community/Social Network',
  'Artificial Intelligence Application',
  'Al on the edge',
  'Fintech Application',
  'Data Mining Tool',
  'Open Banking App',
  'Marketplace',
  'API',
  'Analytics / BI Dashboard',
  'Community/Social Network',
  'Artificial Intelligence Application',
  'Al on the edge',
];
const phone = imageUrl('phone.svg');
const ContactUs = ({ fromInstantQuote }: { fromInstantQuote?: boolean }) => {
  const { t } = useTranslation();
  const { isBrowser } = useSSR();
  const isLessThan768 = useMediaQuery('(max-width: 768px)');
  const isLessThan1280 = useMediaQuery('(max-width: 1280px)');
  const isLessThan640 = useMediaQuery('(max-width: 640px)');

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    reset,
    control,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      email: '',
      message: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required('Name is required'),
        email: yup.string().email('Not a valid email').required('Email is required'),
        message: yup.string().required('Message is required'),
      }),
    ),
  });

  useEffect(() => {
    if (isBrowser) {
      window?.scrollTo(0, 0);
    }
  }, []);

  const [selectedProject, setSelectedProject] = useState<null | string>(null);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState<AlertColor | undefined>('success');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const submitContactForm = async (data: any) => {
    setLoading(true);
    postApi({
      url: '/inquiry',
      data: { name: data.name, email: data.email, message: data.message, apiKey: process.env.REACT_APP_API_KEY },
    })
      .then(() => {
        setLoading(false);
        setOpen(true);
        setSeverity('success');
        setSnackbarMessage('We have received your inquiry and will get back to you within 24 hours.');
        reset();
      })
      .catch((err) => {
        setLoading(false);
        setOpen(true);
        setSeverity('error');
        setSnackbarMessage('An error happened when sending your inquiry, please try again.');
        console.error(err);
      });
  };

  const leftPart = (
    <motion.div
      className={classNames(
        'bg-yellow h-full rounded-lg relative breaktwo:mt-3 breaktwomin:min-w-[400px] breaktwo:max-w-[600px] w-full mx-auto',
        {
          'mb-6': !fromInstantQuote,
        },
      )}
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      exit={{ scale: 0 }}>
      {!fromInstantQuote && (
        <div className="absolute top-0 right-0 w-36 h-36 breaktwo:hidden">
          <img
            loading="lazy"
            className="absolute -top-1/2 -right-1/2 max-w-full"
            src={imageUrl('24X7.svg')}
            alt="24x7"
          />
        </div>
      )}

      <div className="px-6 xs:px-8 sm:px-10 pt-8 xs:pt-10 breaktwomin:pt-16">
        <SubInfo
          containerClasses="text-center"
          typographyStyles={{
            color: '#263238',
            fontFamily: 'var(--fontBase)',
            fontSize: 25,
            fontWeight: 600,
          }}>
          {t('full_package')}
        </SubInfo>
        <div className="flex flex-col">
          <input
            className="outline-none p-3 rounded-lg border-[#263238] border-[1px] mt-5"
            placeholder="Name*"
            {...register('name')}></input>
          {errors && errors.name?.message && <ErrorText text={errors.name?.message} />}
          <input
            className="outline-none p-3 rounded-lg border-[#263238] border-[1px] mt-4"
            placeholder="Email*"
            {...register('email')}></input>
          {errors && errors.email?.message && <ErrorText text={errors.email?.message} />}
          <textarea
            className="outline-none p-3 rounded-lg border-[#263238] border-[1px] mt-4"
            placeholder="Message*"
            rows={4}
            {...register('message')}></textarea>
          {errors && errors.message?.message && <ErrorText text={errors.message?.message} />}
          <RoundedArrowButton
            loading={loading}
            onClick={handleSubmit(submitContactForm)}
            centerText
            text={t('submit')}
            containerClasses="bg-[#263238] mt-5 mb-14"
            typographyStyles={{ color: 'white' }}
            arrowContainerClasses="bg-white"
            arrowColor="#263238"
          />
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity={severity}>{snackbarMessage}</Alert>
          </Snackbar>
        </div>
      </div>
      <img
        loading="lazy"
        className="absolute -bottom-36 -z-10 -left-20 breaktwo:hidden"
        src={imageUrl('flower_upright.svg')}
        alt="flower"
      />
    </motion.div>
  );

  const rightPart = (
    <motion.div
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      exit={{ scale: 0 }}
      className="w-full mx-auto sm1:w-[90%] w-full breaktwomin:min-w-[400px]">
      <div className="flex breaktwo:justify-center">
        <RoundInfo containerClasses="mb-10 mt-10 breaktwo:mt-0 breaktwo:mb-2">{t('contact_us')}</RoundInfo>
      </div>
      <Info variant={'h3'}
        typographyStyles={{ color: '#263238', fontSize: isLessThan1280 ? (isLessThan640 ? 32 : 36) : 42 }}
        containerClasses="breaktwo:text-center">
        {t('tell_us_about_project')}
      </Info>
      <div className="flex gap-[10px] my-[30px]">
        <img className="w-[20px]" width={'20px'} height={'20px'} src={phone} alt="phone" />
        <a
          href="tel:+1(909)503-2187"
          className="text-[#728F8B] text-[17px] hover:text-[#5b9f95]"
          style={{ fontFamily: `var(--fontPrimary)` }}>
          +1 (909) 503-2187
        </a>
      </div>
      <div>
        <CustomLi>{t('reach_out_within_24_hours')}</CustomLi>
        <CustomLi>{t('discuss_your_project')}</CustomLi>
        <CustomLi>{t('free_consultation')}</CustomLi>
      </div>
    </motion.div>
  );

  const additionalFormContainerOne = (
    <div className="border-2 border-t-0 border-[#263238] rounded-bl-lg rounded-br-lg max-h-80 overflow-scroll custom-scroll-bar">
      {projectList.map((each, index) => (
        <SubInfo
          containerClasses="cursor-pointer px-4 py-2 hover:bg-very-light-gray first:mt-2 last:mb-2"
          typographyStyles={{ color: '#263238', fontSize: 16, fontWeight: 100 }}
          key={`subinfo_contactus${index}`}
          onClick={() => {
            setSelectedProject(each);
          }}>
          {each}
        </SubInfo>
      ))}
    </div>
  );

  const FromToBox = ({ boxStyle, amount, label }: { boxStyle?: CSSProperties; amount?: string; label?: string }) => (
    <div className="flex flex-col w-full">
      <div className="mb-1">{label}</div>
      <div style={{ ...boxStyle, paddingTop: 10, paddingBottom: 10, cursor: 'pointer' }} className="flex-1 rounded-lg">
        <div className="flex justify-center items-end">
          <Info typographyStyles={{ fontSize: 50, fontWeight: 500 }}>{amount}</Info>{' '}
          <Info typographyStyles={{ fontSize: 25, fontWeight: 500 }}>K</Info>
        </div>
      </div>
    </div>
  );
  const FromToContainer = () => (
    <div className="flex gap-8 mt-8">
      <FromToBox amount="€62" boxStyle={{ backgroundColor: '#F5CA09' }} label="From" />
      <FromToBox amount="€162" boxStyle={{ backgroundColor: '#FCAE4C' }} label="To" />
    </div>
  );

  const CheckboxRow = ({ fieldName }: { fieldName: string }) => {
    return (
      <div className="flex-1 flex flex-col">
        <SubInfo typographyStyles={{ fontSize: 18, color: '#263238', fontFamily: 'var(--fontSecondary)' }}>
          {fieldName}
        </SubInfo>
        <Checkbox label={t('low')} containerClasses="my-1 mt-4" />
        <Checkbox label={t('medium')} containerClasses="my-1" />
        <Checkbox label={t('high')} containerClasses="my-1" />
      </div>
    );
  };

  const NextBtn = (props: ComponentDivProps) => (
    <div
      className="text-lg cursor-pointer mt-6 mb-4 border-b-2 border-[#263238] flex items-center justify-around gap-1"
      {...props}>
      <div>{t('next')}</div>
      <ArrowForwardIcon style={{ fontSize: 16 }} />
    </div>
  );

  const PrevBtn = (props: ComponentDivProps) => (
    <div
      className="text-lg cursor-pointer mt-6 mb-4 border-b-2 border-[#263238] flex items-center justify-around gap-1"
      {...props}>
      <ArrowBackwardIcon style={{ fontSize: 16 }} />
      <div>{t('prev')}</div>
    </div>
  );

  const returnComponentFromStep = () => {
    if (step === 1) {
      return (
        <div className="rounded-lg border-2 border-[#263238] mt-2 p-4 flex flex-col justify-between min-h-[20rem]">
          <div className="flex">
            <CheckboxRow fieldName={t('complexity')}></CheckboxRow>
            <CheckboxRow fieldName={t('project_size')}></CheckboxRow>
            <CheckboxRow fieldName={t('speed')}></CheckboxRow>
          </div>
          <FromToContainer />
          <div className="w-full flex justify-between">
            <PrevBtn onClick={() => setSelectedProject(null)} />
            <NextBtn onClick={() => setStep((current) => current + 1)} />
          </div>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className="rounded-lg border-2 border-[#263238] mt-2 p-4 flex flex-col justify-between min-h-[20rem]">
          <div className="flex">Step 2</div>
          <div className="w-full flex justify-between">
            <PrevBtn onClick={() => setStep((current) => current - 1)} />
            <NextBtn onClick={() => setStep((current) => current + 1)} />
          </div>
        </div>
      );
    }

    if (step === 3) {
      return (
        <div className="rounded-lg border-2 border-[#263238] mt-2 p-4 flex flex-col justify-between min-h-[20rem]">
          <div className="flex">Step 3</div>
          <div className="w-full flex justify-between">
            <PrevBtn onClick={() => setStep((current) => current - 1)} />
            <NextBtn onClick={() => setStep((current) => current + 1)} />
          </div>
        </div>
      );
    }

    if (step === 4) {
      return (
        <div className="rounded-lg border-2 border-[#263238] mt-2 p-4 flex flex-col justify-between min-h-[20rem]">
          <div className="flex">Step 4</div>
          <div className="w-full flex justify-between">
            <PrevBtn onClick={() => setStep((current) => current - 1)} />
            <NextBtn onClick={() => setStep((current) => current + 1)} />
          </div>
        </div>
      );
    }

    if (step === 5) {
      return (
        <div className="rounded-lg border-2 border-[#263238] mt-2 p-4 flex flex-col justify-between min-h-[20rem]">
          <div className="flex">End Of Steps</div>
        </div>
      );
    }
  };

  const progressBar = Array(5).fill(0);

  const additionalForm = (
    <motion.div
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      exit={{ scale: 0 }}
      className={classNames('flex-1 h-full flex justify-center', {
        'pt-20': !fromInstantQuote,
        'w-full': fromInstantQuote,
        'sm1:w-3/4': fromInstantQuote,
        'breaktwomin:w-full': fromInstantQuote,
        'mx-auto': fromInstantQuote,
      })}>
      <div className="w-[95%]">
        <Info typographyStyles={{ fontFamily: 'var(--fontSecondary)' }} containerClasses="breaktwo:text-center">
          {t('pick_your_type_of_project')}
        </Info>
        <div className="bg-[#263238] w-full h-16 flex items-center pl-4 mt-6 rounded-tl-lg rounded-tr-lg">
          <SubInfo containerClasses="w-[95%]" typographyStyles={{ color: 'white', fontSize: 18 }}>
            {selectedProject || t('pick_your_type_of_project_here')}
          </SubInfo>
          <img
            loading="lazy"
            src={imageUrl('arrow-square-down.svg')}
            className="pr-4 cursor-pointer"
            alt="arrow-down"
          />
        </div>
        {!selectedProject && additionalFormContainerOne}
        {selectedProject && returnComponentFromStep()}
        {selectedProject && (
          <div className="flex gap-2 mt-6">
            {progressBar.map((_, index) => (
              <div
                key={`pgbar${index}`}
                className={classNames('h-1 flex-1', {
                  'bg-yellow': index + 1 <= step,
                  'bg-[#DDDDDD]': index + 1 > step,
                })}></div>
            ))}
          </div>
        )}
      </div>
    </motion.div>
  );

  return (
    <div className="mt-36 md:mt-[6rem] mb-10 mdmax:px-0 px-5 l:px-0 xl:px-0 breaktwo:mt-0 breaktwo:mb-6" id="contactUs">
      <GapAround spaceTaken={1.7}>
        <div
          className={classNames({
            'flex flex-col items-center breaktwomin:flex-row gap-16 breaktwo:gap-0': true,
            'flex-col-reverse': !fromInstantQuote,
          })}>
          <div className="flex-1 w-full">
            <AnimatePresence exitBeforeEnter key={fromInstantQuote as any}>
              {!fromInstantQuote && leftPart}
              {fromInstantQuote && additionalForm}
            </AnimatePresence>
          </div>

          <div
            className={classNames('flex-1', {
              'pb-18': !fromInstantQuote,
              'breaktwo:pb-0': !fromInstantQuote,
              'pb-10': fromInstantQuote,
            })}>
            <AnimatePresence exitBeforeEnter key={fromInstantQuote as any}>
              {fromInstantQuote && leftPart}
              {!fromInstantQuote && rightPart}
            </AnimatePresence>
          </div>
        </div>
      </GapAround>
    </div>
  );
};

export default ContactUs;
