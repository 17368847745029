import React from 'react';
import GapAround from '../hoc/GapAround';
import WithNavbarHeightApplied from '../hoc/WithNavbarHeightApplied';
import BigColumn from '../layout/BigColumn';
import Info from './Info';
import RoundInfo from './RoundInfo';
import SubInfo from './SubInfo';
import { useTranslation } from 'react-i18next';
import { useMediaQueries } from '../../contexts/media-query';

function Banner(props: any) {
  const { pillLabel, title, description } = props;
  const { t } = useTranslation();
  const { bigColumnClass, isLessThan1280, isLessThan640 } = useMediaQueries();

  return (
    <WithNavbarHeightApplied>
      <GapAround>
        <BigColumn
          containerStyle={bigColumnClass}
          childStyle={{ alignItems: 'end', justifyContent: bigColumnClass ? 'flex-end' : 'center' }}>
          {/* Left information section start */}
          <div className="mt-10 breaktwo:mt-3 px-5 breaktwo:flex breaktwo:flex-col justify-center items-end breaktwo:text-center  breaktwo:items-center breaktwo:justify-center before:bg-top before:absolute before:w-full before:h-full before:-z-10  before:opacity-20">
            <RoundInfo containerClasses="breaktwo:mb-3 mb-6 breaktwo:mt-6">{t(pillLabel)}</RoundInfo>
            <Info
            variant={'h2'}
              typographyStyles={{
                fontSize: isLessThan1280 ? (isLessThan640 ? 32 : 36) : 42,
              }}>
              {t(title)}
            </Info>
          </div>
          {/* Left information section end */}
          <div className="flex justify-end breaktwo:justify-center breaktwo:mt-5 breaktwo:text-center breakone:text-center">
            <SubInfo containerClasses="w-[90%] items-end" typographyStyles={{ fontFamily: 'var(--fontPrimary)' }}>
              {t(description)}
            </SubInfo>
          </div>
        </BigColumn>
      </GapAround>
    </WithNavbarHeightApplied>
  );
}

export default Banner;
