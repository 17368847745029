import { Link } from 'react-router-dom';
import GapAround from '../../components/hoc/GapAround';

const Page404 = () => {
  return (
    <div className="wrapper bottom-zero">
      <GapAround>
        <div className="pt-[30px] pb-[80px] text-center min-h-[calc(100vh-611px)] md:min-h-[calc(100vh-439px)] breaktwomin:min-h-[calc(100vh-499px)] flex flex-col justify-center items-center">
          <div className="max-w-[620px]">
            <h2 className="mb-0 text-[30px]">Oops! Page Not Found</h2>
            <p className='mb-[30px]'>Sorry, the page you are looking for doesn't exist or has been moved. Try going back.</p>
          </div>
          <Link className={'py-[10px] px-[25px] bg-[#000] text-[#fff] flex rounded-[30px]'} to="/">
            Back to Homepage
          </Link>
        </div>
      </GapAround>
    </div>
  );
};
export default Page404;
