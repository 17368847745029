import React from 'react';
import Header from '../components/common/Header';
import ContactUs from '../views/landing/ContactUs';
import { Helmet } from 'react-helmet-async';

interface Props {}

const ContactUsPage = (props: Props) => {
  const {} = props;

  return (
    <>
      <Helmet>
        <title>Contact Atini Studio: App Development & Web Design Agency in USA</title>
        <meta
          name="description"
          content="Contact Atini Studio for custom web and app development, web design, and software consulting services. Let's create impactful solutions to grow your business."
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://atinistudio.com/contact-us" />
        <meta property="og:title" content="Contact Atini Studio: App Development & Web Design Agency in USA" />
        <meta
          property="og:description"
          content="Contact Atini Studio for custom web and app development, web design, and software consulting services. Let’s create impactful solutions to grow your business."
        />
        <meta property="og:image" content="https://atinistudio.com/assets/atini-logo-colored.svg" />
        <meta property="og:site_name" content="Atini Studio" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:phone_number" content="+1 (909) 503-2187" />
        <meta property="og:email" content="general@atinistudio.com" />
        <link rel="alternate" hrefLang="en-US" href="https://atinistudio.com/contact-us" />
          <link rel="canonical" href="https://atinistudio.com/contact-us" />
      </Helmet>
      <Header
        pillLabel="contact_us"
        title="content_us_motto"
        description="contact_us_description"
        image="contact_us.svg"
        renderButton={false}
        altTag="App development agency near me"
      />
      <ContactUs />
      <iframe
        className="w-full border-none"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3227.74278254182!2d-115.1945229241979!3d36.00215097248542!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c8c9338293a8d7%3A0x4bb00a4f780bd57b!2s3946%20Painted%20Lady%20Ave%2C%20Las%20Vegas%2C%20NV%2089141%2C%20USA!5e0!3m2!1sen!2sin!4v1733898241774!5m2!1sen!2sin"
        width="600"
        height="450"
        loading="lazy"
        data-categories="essential"
        data-allow-scripts="true"
        referrerPolicy="no-referrer-when-downgrade"></iframe>
    </>
  );
};

export default ContactUsPage;
