import { Typography } from '@mui/material';
import classNames from 'classnames';
import { CSSProperties } from 'react';
import { TwContainer } from '../../typings/tw.types';
import { imageUrl } from '../../utils';
interface ResultsBoxProps extends TwContainer {
  heading: string;
  subheading: string;
  describeImage: string;
  headingStyle?: CSSProperties;
  subHeadingStyle?: CSSProperties;
  subHeadingClass?: string;
  iconStyle?: CSSProperties;
  iconClass?: string;
  noWidth?: boolean;
  className?: string;
  imgClass?: string;
  alt?: string;
}

const ResultBox = ({
  containerClasses,
  heading,
  subheading,
  describeImage,
  headingStyle,
  subHeadingStyle,
  subHeadingClass,
  iconStyle,
  iconClass,
  noWidth,
  className,
  imgClass,alt
}: ResultsBoxProps) => {
  return (
    <div
      className={classNames(
        'rounded-xl shadow-sm mdmax:!mx-0',
        {
          'w-full sm:w-1/2 lg:w-1/3': !noWidth,
        },
        containerClasses,
      )}>
      <div className="flex justify-between items-center">
        <div>
          <Typography variant="cardheading" className={className} style={headingStyle}>
            {heading}
          </Typography>
        </div>

        <div style={iconStyle}  className={iconClass}>
          <img className={imgClass} loading="lazy" width={'50px'} height={'50px'} src={imageUrl(describeImage)} alt={alt ||"" } />
        </div>
      </div>
      <div>
        <Typography className={subHeadingClass} style={{ fontFamily: 'var(--fontPrimary)', ...subHeadingStyle }}>{subheading}</Typography>
      </div>
    </div>
  );
};

export default ResultBox;
