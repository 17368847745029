import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RoundedArrowButton } from '../../components/buttons';
import ServiceCard from '../../components/common/ServiceCard';
import SubInfo from '../../components/common/SubInfo';
import BigCard from '../../components/layout/BigCard';
import { useMediaQuery } from '@mui/material';

const ServicesSection = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isLessThan640 = useMediaQuery('(max-width: 640px)');

  const services = [
    {
      title: t('web_dev'),
      description: t('web_dev_desc'),
      icon: 'webapp_development_icon.svg',
      alt:"Custom web application development LA"
    },
    {
      title: t('mobile_dev'),
      description: t('mobile_dev_desc'),
      icon: 'mobile_app_development.svg',
      alt:"Top mobile app development company LA"
    },
    {
      title: t('ui_ux'),
      description: t('ui_ux_desc'),
      icon: 'uiux_development_icon.svg',
      alt:"Web design & development agency USA"
    },
    {
      title: t('software_consultation'),
      description: t('software_consultation_desc'),
      icon: 'software_development_icon.svg',
      alt:"Software Consulting Services LA"
    },
  ];

  const TopRightElement = (
    <>
      <div className="flex flex-col items-start breaktwo:items-center">
        <SubInfo
          typographyStyles={{ color: '#263238', fontSize: isLessThan640 ? 15 : 17 }}
          containerClasses="mb-5 breaktwo:mt-3">
          {t('one_stop_shop')}
        </SubInfo>
        <RoundedArrowButton
          text={t('get_instant_quote')}
          containerClasses="bg-light-black w-72"
          arrowColor="#263238"
          arrowContainerClasses="bg-white"
          typographyStyles={{ color: 'white', fontWeight: 'light' }}
          onClick={() => {
            navigate('/contact-us');
          }}
        />
      </div>
    </>
  );

  const BottomElement = (
    <Grid container columnSpacing={8} rowSpacing={0}>
      {services.map(({ title, description, icon,alt }, index) => (
        <Grid item xs={12} md={6} key={index}>
          <ServiceCard title={title} description={description} icon={icon} alt={alt} />
        </Grid>
      ))}
    </Grid>
  );

  return (
    <BigCard
      roundInfoText={t('expert_team')}
      mainInfoText={t('full_potential_services')}
      topRightElement={TopRightElement}
      bottomElement={BottomElement}
      containerClasses="bg-yellow"
      bottomContainerClasses="w-11/12 sm:w-9/12"
      id="services"
      spaceTaken={0.75}
    />
  );
};

export default ServicesSection;
