import { imageUrl } from '../../utils';
import { Typography } from '@mui/material';

interface Props {
  label: string;
}

const SoftwareItem = (props: Props) => {
  const { label } = props;

  return (
    <div className="grid-item">
      <div className="flex gap-3 items-center">
        <img loading="lazy" src={imageUrl('software_item_dot.svg')} alt='Dot icon' />
        <Typography
          style={{
            fontFamily: 'var(--fontPrimary)',
            fontSize: '16px',
            textAlign: 'left',
          }}>
          {label}
        </Typography>
      </div>
    </div>
  );
};

export default SoftwareItem;
