export const ux_ServiceItems = [
    {
        title: 'Design Consultations',
        description: "Share your ideas, and we'll help you brainstorm and set the wheels in motion.",
        iconSrc: 'consulting_services.svg',
    },
    {
        title: 'Prototyping',
        description: 'Turning your concepts into interactive models, allowing you to visualize and refine your ideas.',
        iconSrc: 'prototyping.svg',
    },
    {
        title: 'Web App Design',
        description: 'Crafting responsive, user-friendly interfaces for seamless experiences across all browsers. As a top web app design agency in LA, we ensure flawless performance.',
        iconSrc: 'web_design.svg',
    },
    {
        title: 'Mobile App Design',
        description: 'Delivering intuitive, engaging interfaces for a seamless experience across all devices with our app design services in LA.',
        iconSrc: 'mobile_app_design.svg',
    },
    {
        title: 'Wireframing',
        description: 'We help visualize the structure and functionality of your project, laying the groundwork for effective design and development.',
        iconSrc: 'wireframing.svg',
    },
    // {
    //     title: 'UI Design Services',
    //     description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi ultrices amet, pretium commodo.',
    //     iconSrc: 'ui_design_services.svg',
    // },
];

export const software_serviceItems = [
    {
        title: 'Software Launch',
        description: 'Ready to launch? Our consultation will guide you on key considerations to ensure a smooth software rollout.',
        iconSrc: 'software_launch_consulting.svg',
    },
    {
        title: 'Technology Stack Selection',
        description: 'Our consultation helps you choose the right tech stack to align with your project goals and ensure optimal performance.',
        iconSrc: 'technology_stack_identification.svg',
    },
    {
        title: 'Software Integration & Enhancement',
        description: 'We provide insights to enhance your software through effective integration and optimization.',
        iconSrc: 'software_integration_enhancement_consulting.svg',
    },
    {
        title: 'Software Compliance',
        description: 'Ensuring your software meets compliance standards and regulations for a secure and reliable operation.',
        iconSrc: 'software_compliance_consulting.svg',
    },
    // {
    //     title: 'Digital Transformation',
    //     description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi ultrices amet, pretium commodo.',
    //     iconSrc: 'digital_transformation_consulting.svg',
    // },
];

export const web_dev_service_items = [
    {
        title: 'Custom Web App Development',
        description: 'We transform your unique ideas into reality, delivering solutions for every requirement.',
        iconSrc: 'code_editor.svg',
    },
    {
        title: 'SaaS Web App Development',
        description: 'Providing scalable cloud-based applications designed to meet your business needs.',
        iconSrc: 'gear_cloud.svg',
    },
    {
        title: 'eCommerce Development',
        description: 'We offer robust eCommerce development services to create engaging and effective online shopping platforms.',
        iconSrc: 'cart.svg',
    },
    {
        title: 'SEO-Driven Development',
        description: 'Boosting online visibility and search engine rankings through site speed optimization, on-page SEO, strategic keyword research, and more.',
        iconSrc: 'seo.svg',
    },
    {
        title: 'Web Portal Development',
        description: 'Creating customized web portals that provide centralized access to information and services tailored to meet your needs.',
        iconSrc: 'comp.svg',
    },
    {
        title: 'Progressive Web App Development',
        description: 'Providing cross-platform compatibility for seamless access on all devices.',
        iconSrc: 'globe.svg',
    },
    {
        title: 'Custom CMS Development',
        description: 'Empowering you to create, manage, and optimize your digital content effortlessly.',
        iconSrc: 'cms.svg',
    },
    {
        title: 'Enterprise Web Development',
        description: 'We deliver highly scalable solutions to meet the complex needs of large organizations.',
        iconSrc: 'community.svg',
    },
];