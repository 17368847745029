import React from 'react'
import ClientsSection from '../views/landing/ClientsSection'
import ProjectsSection from '../views/landing/ProjectsSection'
import { Helmet } from 'react-helmet-async'

interface Props { }

const Projects = (props: Props) => {
    const { } = props

    return (
      <>
        <Helmet>
          <title>Atini Studio Case Studies | App & UI/UX Design Portfolio</title>
          <meta
            name="description"
            content="Browse Atini Studio’s curated project showcase featuring top-notch app development and UI/UX design projects that showcase our expertise and creativity."
          />

          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://atinistudio.com/projects" />
          <meta property="og:title" content="Atini Studio Case Studies | App & UI/UX Design Portfolio" />
          <meta
            property="og:description"
            content="Explore Atini Studio's curated project showcase featuring exceptional app development and UI/UX design, demonstrating our expertise and creativity in crafting digital solutions."
          />
          <meta property="og:image" content="https://atinistudio.com/assets/atini-logo-colored.svg" />
          <meta property="og:site_name" content="Atini Studio" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:phone_number" content="+1 (909) 503-2187" />
          <meta property="og:email" content="general@atinistudio.com" />
          <link rel="alternate" hrefLang="en-US" href="https://atinistudio.com/projects" />
          <link rel="canonical" href="https://atinistudio.com/projects" />
        </Helmet>
        <div style={{ marginTop: '12rem' }}>
          <ProjectsSection />
          <div className="mb-10">
            <ClientsSection />
          </div>
        </div>
      </>
    );
}

export default Projects