import classNames from 'classnames';
import { TwContainer } from '../../typings/tw.types';
import Info from '../common/Info';
import RoundInfo from '../common/RoundInfo';
import { useMediaQuery } from '@mui/material';
import { ReactElement } from 'react';
import SubInfo from '../common/SubInfo';

interface BigCardProps extends TwContainer {
  topLeftElement?: JSX.Element;
  topRightElement?: JSX.Element;
  bottomElement?: JSX.Element;
  spaceTaken?: number;
  roundInfoText?: string;
  mainInfoText?: string | ReactElement;
  bottomContainerClasses?: string;
  topRightContainerClasses?: string;
  noBorder?: boolean;
  fromClients?: boolean;
  description?: string;
}

const BigCard = ({
  topRightElement,
  bottomElement,
  spaceTaken = 0.5,
  containerClasses,
  roundInfoText = 'Please provide text here',
  mainInfoText = 'Please provide text here',
  bottomContainerClasses,
  topRightContainerClasses,
  noBorder = false,
  id,
  fromClients,
  description
}: BigCardProps) => {
  const isLessThan640 = useMediaQuery('(max-width: 640px)');
  const isLessThan1280 = useMediaQuery('(max-width: 1280px)');
  const isLessThan2440 = useMediaQuery('(max-width: 2440px)');

  return (
      <div
        className={classNames('rounded-[10px] flex flex-col items-center pt-3 breaktwo:pt-0', containerClasses)}
        id={id}>
        <div
          className={classNames(isLessThan2440 ? 'max-w-[1100px]' : 'max-w-[1320px]', !fromClients && 'py-8 items-end breaktwo:pt-2', 'w-9/12 breaktwo:w-full flex justify-between border-light-black breakthree:flex-col breaktwo:items-center breaktwo:text-center')}>
          <div className="w-1/2 breaktwo:w-full breaktwo:flex breaktwo:flex-col breaktwo:items-center">
            <RoundInfo containerClasses="mb-5 mt-10">{roundInfoText}</RoundInfo>
            <Info variant={"h2"}typographyStyles={{ color: '#263238', fontSize: isLessThan1280 ? (isLessThan640 ? 32 : 36) : 42 }}>{mainInfoText}</Info>
            <SubInfo containerClasses="md:w-[80%] my-4" typographyStyles={{ fontFamily: 'var(--fontPrimary)' }}>
                {(description || '')}
              </SubInfo>
          </div>
          <div className={classNames('w-1/2 breaktwo:w-full breaktwo:mt-2', topRightContainerClasses)}>{topRightElement}</div>
        </div>
        <div
          className={classNames(isLessThan2440 ? 'max-w-[1100px]' : 'max-w-[1320px]', bottomContainerClasses, '!pb-10 flex', {
            'pt-10': !noBorder,
          })}>
          {bottomElement}
        </div>
      </div>
  );
};

export default BigCard;
