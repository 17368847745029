import React from 'react'
import Header from '../components/common/Header'
import Banner from '../components/common/Banner'
import ServiceList from '../views/dev/ServiceList';
import ProjectsSection from '../views/landing/ProjectsSection';
import { ux_ServiceItems } from '../constants/servicesItems';
import FaqSection from '../views/landing/FaqSection';
import {uiuxFaqs} from '../constants/uiuxFaqs';
import { Helmet } from 'react-helmet-async';
interface Props { }

const Ux = (props: Props) => {
    const { } = props

    return (
      <>
        <Helmet>
          <title>App Design Services in LA | UI/UX Agency in Los Angeles, USA</title>
          <meta
            name="description"
            content="Atini Studio provides exceptional website design services in LA, combining expert user interface design with app design services to create a flawless user journey."
          />
            <link rel="alternate" hrefLang="en-US" href="https://atinistudio.com/ui-ux" />
            <link rel="canonical" href="https://atinistudio.com/ui-ux" />
        </Helmet>
        <Header
          pillLabel="Ui_Ux_Pill"
          title="Ui_Ux"
          description="ui_ux_description"
          renderButton
          buttonText="get_instant_quote"
          image="ux_header.svg"
          altTag="UI/UX Agency in Los Angeles "
        />
        <Banner pillLabel="elegant_design_pill" title="ui_ux_banner_motto" description="ui_ux_banner_description" />
        <ServiceList items={ux_ServiceItems} />
        <ProjectsSection />
        <FaqSection questionAnswerArray={uiuxFaqs} />
      </>
    );
}
export default Ux