import Header from '../components/common/Header';
import ContactUs from '../views/landing/ContactUs';
import StepsWeFollow from '../views/landing/StepsWeFollow';
import WhyUs from '../views/landing/WhyUs';
import TrustedBySection from '../views/landing/TrustedBySection';
import { Helmet } from 'react-helmet-async';

const LandingPage = () => {
  return (
    <>
      <Helmet>
        <title>Web & App Development Company | Software Consultancy Agency in USA</title>
        <meta
          name="description"
          content="Discover the best web design company in USA offering mobile app development, software consultancy, and comprehensive web & app development solutions."
        />
        <link rel="preload" as="image" href="assets/ladder-to-cup.webp" />
        <link rel="alternate" hrefLang="en-US" href="https://atinistudio.com/" />
    <link rel="canonical" href="https://atinistudio.com/" />
      </Helmet>
      <Header
        pillLabel="our_team_your_team"
        title="motto"
        description="who_are_we"
        image="ladder-to-cup.webp"
        renderButton
        buttonText="get_instant_quote"
        altTag="ladder to cup"
      />
      <p></p>
      
      <TrustedBySection />
      <WhyUs />
      <StepsWeFollow />
      <ContactUs />
    </>
  );
};

export default LandingPage;
