import { RoundedArrowButton } from '../../components/buttons';
import WithNavbarHeightApplied from '../../components/hoc/WithNavbarHeightApplied';
import GapAround from '../../components/hoc/GapAround';
import BigColumn from '../../components/layout/BigColumn';
import { imageUrl } from '../../utils';
import SubInfo from '../../components/common/SubInfo';
import Info from '../../components/common/Info';
import RoundInfo from '../../components/common/RoundInfo';
import { useTranslation } from 'react-i18next';
import { useMediaQueries } from '../../contexts/media-query';
import { FlowerFour, FlowerOne } from './Flowers';
import { useNavigate } from 'react-router-dom';

const Header = ({ pillLabel, title, description, image, renderButton, buttonText,altTag }: any) => {
  const { t } = useTranslation();
  const { bigColumnClass } = useMediaQueries();
  const imgSrc = imageUrl(image);
  const navigate = useNavigate();

  return (
    <div>
      <FlowerOne />
      <FlowerFour />
      <WithNavbarHeightApplied>
        <GapAround spaceTaken={2} containerClass={'overflow-hidden'}>
          <BigColumn containerStyle={bigColumnClass} className="isLessThan1036:pb-[10px] pb-[3rem] item-center">
            <div className="breaktwo:pb-5 relative w-[100%] self-center breaktwo:flex breaktwo:flex-col justify-center items-center breaktwo:text-center header-image mdmax:mx-[-20px] mdmax:px-[20px] mdmax:w-[calc(100%+40px)]">
              <div
                className="absolute left-0 top-0 w-[100%] opacity-[0.2] h-[100%] z-[-1] bg-no-repeat bg-top md:hidden text-[0]"
                style={{ backgroundImage: `url(${imgSrc})` }}>{altTag}</div>
              <RoundInfo containerClasses="breaktwo:mb-3 mb-6 breaktwo:mt-6">{t(pillLabel || '')}</RoundInfo>
              <Info className="lg:!text-[42px] md:!text-[36px] !text-[32px]">{t(title || '')}</Info>
              <SubInfo containerClasses="md:w-[80%] my-4" typographyStyles={{ fontFamily: 'var(--fontPrimary)' }}>
                {t(description || '')}
              </SubInfo>
              {renderButton && (
                <div className="breaktwo:mt-5 mt-10">
                  <RoundedArrowButton
                    movingArrow
                    text={t(buttonText)}
                    containerClasses="bg-yellow w-72"
                    onClick={() => navigate('/contact-us')}
                  />
                </div>
              )}
            </div>
            {/* Left information section end */}
            {/* Right image section start */}
            <div
              className="w-full h-full flex items-center -z-10 justify-end breaktwo:hidden mt-5"
              style={{ minHeight: '500px' }}>
              <img alt={altTag} className="w-[500px]" src={imgSrc} />
            </div>
            {/* Right image section end */}
          </BigColumn>
        </GapAround>
      </WithNavbarHeightApplied>
    </div>
  );
};

export default Header;
